<template>
  <div class="vg_wrapper">
    <el-tabs
        v-model="activeName"
        type="border-card"
    >
      <el-tab-pane label="单据信息" name="first" :key="'first'" >
        <childTab1 @isShow="isShow" ref="childTab1"/>
      </el-tab-pane>
      <el-tab-pane label="审批流转" name="second" :key="'second'" :lazy="true" >
        <childTab2 :isShow="isSho" @draftState="draftState"/>
      </el-tab-pane>
      <el-tab-pane label="相关文档" name="third" :key="'third'" :lazy="true">
        <childTab3 :isShow="isSho"/>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import PodrEditMain from "@/views/PurchaseManagement/PodrManage/TabChild/PodrEditMain";
import PodrEditAppr from "@/views/PurchaseManagement/PodrManage/TabChild/PodrEditAppr";
import PodrEditAbout from "@/views/PurchaseManagement/PodrManage/TabChild/PodrEditAbout";
export default {
  name: 'PodrEdit',
  components: {
    childTab1: PodrEditMain,
    childTab2: PodrEditAppr,
    childTab3: PodrEditAbout,
  },
  data() {
    return {
      activeName: "first",
      isSho:true,
    }
  },
  created() {},
  methods: {
    isShow(val){
      this.isSho = val;
    },
    draftState(){
      this.$refs.childTab1.initData()
    },
  }
};
</script>

<style scoped lang="scss">

</style>