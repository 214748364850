import topic from '../topic'
export const podrAPI = {
    getPodrs: topic.podrTopic + '/get_podrs',
    getPodrById: topic.podrTopic + '/get_podr_by_id',
    getPodrProdMtrls: topic.podrTopic + '/get_podr_prod_mtrls',
    getPodrProdPrcs: topic.podrTopic + '/get_podr_prod_prcs',
    getPodrProdRout: topic.podrTopic + '/get_podr_prod_rout',
    addPodr: topic.podrTopic + '/add_podr',
    editPodr: topic.podrTopic + '/edit_podr',
    deletePodrByIds: topic.podrTopic + '/delete_podr_by_ids',
    podrShareAccts: topic.podrTopic + '/podr_share_accts',
}