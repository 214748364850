<template>
 <div>
   <appr-main :isShow="isShow" @draftState="draftState"></appr-main>
 </div>
</template>

<script>
import apprMain from "@/views/component/apprCommon/apprMain";
export default {
name: "SmplEditAppr",
props:{
  isShow:{
    type:Boolean,
    required:true,
  }
},
components: {
    apprMain
  },
  methods:{
    draftState(){
      this.$emit('draftState')
    }
  }
}
</script>

<style scoped>

</style>