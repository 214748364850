import { render, staticRenderFns } from "./PodrEditAbout.vue?vue&type=template&id=722861a3&scoped=true&"
import script from "./PodrEditAbout.vue?vue&type=script&lang=js&"
export * from "./PodrEditAbout.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "722861a3",
  null
  
)

export default component.exports