<template>
  <!-- <el-card> -->
    <div class="vd_search_group vg_mtb_16">
      <el-form :inline="true" size="min" :model="searchForm" ref="searchForm" @keyup.enter.native="getProdsNow()">
        <el-row>
          <el-col :md="7">
            <el-form-item label="产品编号:">
              <el-input size="small" v-model.trim="searchForm.prod_no" clearable placeholder="请填写产品编号"></el-input>
            </el-form-item>
          </el-col>
          <el-col :md="12">
            <el-form-item label="产品名称:">
              <el-input size="small" v-model.trim="searchForm.prod_name" clearable placeholder="请填写产品名称"></el-input>
            </el-form-item>
          <!-- </el-col>
          <el-col :md="3"> -->
            <el-form-item >
              <el-button size="small" type="primary" icon="el-icon-search" @click="getProdsNow()" class="vg_ml_16">查询</el-button>
              <el-button size="small" type="info" icon="el-icon-refresh" @click="getProdsNowO()" class="vg_ml_16">刷新</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <el-row class="vd_mar15">
        <el-button size="mini" type="danger" @click="cancel">取消选择</el-button>
        <el-button size="mini" type="primary" @click="confirmIn()">确认选择</el-button>
      </el-row> 
      <el-row class="vd_mar15">
        <div><i class="el-icon-info vg_mr_8"></i>{{title}}</div>
      </el-row>
      <el-row>
        <el-col :md="24">
          <el-table ref="multiTable" :data="tableData" @selection-change="handleSelectionChange" :row-key="getRowKey" border v-loading="loadingFlag">
            <!-- @selection-change="handleSelectionChange" -->
            <el-table-column type="selection" width="48"  align="center" :reserve-selection="true"/>
            <el-table-column label="产品编号" prop="prod_no"/>
            <el-table-column label="产品名称" prop="prod_name" />
            <el-table-column label="产品规格">
              <template slot-scope="scope">
                <span v-if="scope.row.prod_spec">
                  {{scope.row.prod_spec}}
                </span>
                <span v-else class="vg_9f9a9a">暂无</span>
              </template>
            </el-table-column>
            <el-table-column label="录入时间" prop="create_time" :formatter="formatDate" />
            <!-- <el-table-column label="产品状态" align="center">
              <template slot-scope="scope">
                <el-tag :type="helper.getStatusName(scope.row.status).type" size="mini">{{helper.getStatusName(scope.row.status).name}}</el-tag>
              </template>
            </el-table-column> -->
          </el-table>
        </el-col>
      </el-row>
      <el-row>
      <el-col :md="24">
        <pubPagination :totalPage = 'totalPage' @changePageSearch = "changePageSearch" ref="pubPagination"></pubPagination>
      </el-col>
    </el-row>
    </div>
  <!-- </el-card> -->
</template>

<script>
import {get} from "@api/request";
import {prodAPI} from "@api/modules/prod"
import options from "@/views/component/common/options";
import pubPagination from "@/components/common/pubPagination";

export default{
  name:"PodrEditIn",
  components:{
    pubPagination,
  },
  props:{
  },
  data(){
    return{
      tableData:[],
      searchForm: {
        prod_no: '',
        prod_name:null,
      },
      totalPage:0,
      btn:{},
      loading: true,
      multiSelection: [],
      currentPage:1,
      flag:false,
      prodTypeGroupOpt:[],
      loadingFlag:true,
      title:'提示：相同数据仅限导入一条',
    }
  },
  created(){
    this.initData()
  },
  methods:{
    initData(){
      this.loadingFlag = true
      this.getProdsList()
      this.mtrlTypeGroupCrft = options.mtrlUnit;
    },
    // 获取样品信息
    getProdsList() {
      const timeNewVal = this.helper.getTime(this.searchForm.timeValue)
      get(prodAPI.getProds,
        {
          prod_no: this.searchForm.prod_no,
          prod_name: this.searchForm.prod_name,
          status: 2,
          start_time: timeNewVal.startTime,
          end_time: timeNewVal.endTime,
          page_no: this.currentPage,
        })
        .then(res => {
          if(res.data.code === 0){
            this.tableData = res.data.data.list;
            this.totalPage = res.data.data.total
            this.btn = res.data.data.btn;
            setTimeout(() => {
              this.loadingFlag = false
            }, 500);
          } else {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({message:mg,type:tp});
          }
        })
        .catch(res=>{
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({message:mg,type:tp});
        })
    },
    // 时间转换
    formatDate(row) {
      return this.helper.toStringDate(row.create_time)
    },
    // 获取样品状态
    getProdType(flag){
      if(flag === true && this.prodTypeGroupOpt.length === 0){
        this.prodTypeGroupOpt = options.smplStatus
      }
    },
    //样品状态
    formatProdType(row){
      if(row.status === 0){
        return '草拟'
      } else if(row.status === 1){
        return '在批'
      } else if(row.status === 2){
        return '生效'
      }
    },
    // 查询方法
    getProdsNow(){
      this.$refs.pubPagination.currentPage = 1
      this.currentPage = 1
      this.initData()
    },
    // 刷新
    getProdsNowO(){
      this.$refs.pubPagination.currentPage = 1
      this.currentPage = 1
      this.searchForm= {
        prod_no: '',
        prod_name:null,
      }
      this.initData()
    },
    // 清空
    clear(){
      this.$refs.multiTable.clearSelection();
    },
    // 指定一个key标识这一行的数据
    getRowKey (row) {
      return row.prod_id;
    },
    // 多选
    handleSelectionChange(val){
      this.multiSelection = val;
    },
    // 取消选择
    cancel(){
      this.$emit("cancelPodrIn")
      this.clear()
    },
    // 确认选择
    confirmIn(){
      if(this.multiSelection.length>0){
        this.$emit("confirmIn",this.multiSelection)
      }else{
        this.$message.warning("至少选择一条数据")
      }
      
    },
    // 分页查询
    changePageSearch(val){
      this.currentPage = val
      this.initData()
    },
  }
}
</script>

<style lang="scss" scoped>
.vd_elimg {
  width: 60px;
  height: 60px;
}
.vd_mrt{
  margin-top: 20px;
}
.vd_mar15{
  margin: 15px 0;
}
</style>
