<template>
  <div class="vg_wrapper" v-loading="loadingFlag">
    <div class="vd_edhea">
     <editHeader
        :isShow="isShow"
        :btn="btn"
        :strForm="podrForm"
        @openEdit="openEdit"
        @closeEdit="closeEdit"
        @submit="submit('podrForm')"/> 
      <el-button type="primary" size="small" class="vd_export" @click="buttonRefresh()" v-if="isShow">刷新</el-button>
      <el-button @click="drawer = true" class="vd_export1" v-if="specialDataFlag && isShow" size="small" type="warning">分享账套</el-button>
      <!-- <el-button type="primary" :disabled="isDisable" size="small" class="vd_exports" @click="takee()" v-if="isShow">生成成品合同</el-button> -->
    </div>
    <el-form ref="podrForm" :rules="rules" :disabled="isShow" :model="podrForm" label-width="120px" size="mini">
      <el-row>
        <el-col :md="8">
          <el-form-item label="订单编号" prop="podr_no">
            <el-input v-model="podrForm.podr_no" clearable disabled maxlength="30"  show-word-limit placeholder="请填写订单编号">
            </el-input>
          </el-form-item>
          <el-form-item label="交货时间" prop="podr_date">
            <el-date-picker
              :disabled="!specialDataFlag"
              v-model="podrForm.podr_date"
              type="date"
              placeholder="选择日期">
            </el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="采购方" prop="cptt_id">
            <!-- <el-select v-model="podrForm.cptt_id" disabled placeholder="请选择采购方" clearable>
              <el-option
                  v-for="item in cpttFctrGroupOpt"
                  :key="item.cptt_id"
                  :label="item.cptt_name"
                  :value="item.cptt_id">
              </el-option>
            </el-select> -->
            <el-input  v-model="podrForm.purc_cptt_name" disabled placeholder="请输入采购方" clearable></el-input>
          </el-form-item>
          <el-form-item label="总金额" prop="podr_total">
            <el-input v-model="podrForm.podr_total" disabled maxlength="11" @input ="podrForm.podr_total = helper.keepTNum1(podrForm.podr_total)" @blur ="podrForm.podr_total = helper.haveFour(podrForm.podr_total)" show-word-limit placeholder="请填写其他金额"></el-input>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="销售方" prop="podr_cptt_id">
            <!-- <el-select v-model="podrForm.podr_cptt_id" disabled placeholder="请选择销售方" clearable>
              <el-option
                  v-for="item in cpptForm"
                  :key="item.cptt_id"
                  :label="item.cptt_name"
                  :value="item.cptt_id">
              </el-option>
            </el-select> -->
            <el-input v-model="podrForm.podr_cptt_name" disabled placeholder="请输入销售方" clearable></el-input>
          </el-form-item>
          <el-form-item label="其他金额" prop="extra_price">
            <el-input @change="extraPriceChange" v-model="podrForm.extra_price" maxlength="10" @input ="podrForm.extra_price = helper.keepTNum1(podrForm.extra_price)"  show-word-limit placeholder="请填写其他金额"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <div class="vg_button">
        <el-button type="primary" plain size="small" :disabled='podrForm.status===2' @click="addRow()">导入</el-button>
        <el-button type="danger" plain size="small" :disabled='podrForm.status===2'  @click="delBank()">删除</el-button>
      </div>
      <el-row class="vd_mrt">
        <PodrAddProd  :isShow="isShow" @handleSelectionChange="handleSelect" :prodForm="podrForm"></PodrAddProd>
      </el-row>
      <el-dialog
        :title="title"
        :visible.sync="notice"
        width="70%">
        <PodrAddEditIn :notice="notice" @cancelPodrIn="cancelPodrIn" @confirmIn="confirmIn" ref="PodrAddEditIn"></PodrAddEditIn>
      </el-dialog>
      <el-row class="vg_mt_16 vd_bortop">
        <el-col>
          <inputUser :isCookie="false" ref="userData" :stffForm="stffForm" v-if="podrForm.stff_name"></inputUser>
        </el-col>
     </el-row>
    </el-form>
    <el-drawer :visible.sync="drawer" :direction="direction" :before-close="handleCloseDrawer">
      <div class="vd_draw">
        <el-card>
          <el-button type="success" class="vg_mb_16" size="small" v-if="!acctPreseFlag" @click="acctPreseFlag = true">编辑</el-button>
          <el-button type="primary" class="vg_mb_16" size="small"  v-if="acctPreseFlag" @click="preseType()">保存</el-button>
          <el-button class="vg_mb_16" size="small" v-if="acctPreseFlag"  @click="cancelType()">取消</el-button>
          <el-button class="vg_mb_16" type="primary" size="small" v-if="acctPreseFlag" plain @click="addType()">添加</el-button>
          <el-table :data="podrForm.podr_acct_list" border>
            <el-table-column label="账套名称">
              <template slot-scope="scope">
                <el-select size="small" @change="acctSelect(scope)" :disabled="podrForm.podr_acct_list[scope.$index].podr_acct_share === 1 || !acctPreseFlag" v-model="podrForm.podr_acct_list[scope.$index].acct_id" placeholder="请选择账套">
                  <el-option
                    v-for="item in acctCopy"
                    :key="item.acct_id"
                    :label="item.acct_no"
                    :value="item.acct_id"
                    :disabled="item.disabled">
                  </el-option>
                </el-select>
              </template>
            </el-table-column>
            <el-table-column align="center" width="150px">
              <template slot-scope="scope">
                <el-link type="primary" v-if="podrForm.podr_acct_list[scope.$index].podr_acct_share === 1">系统</el-link>
                <el-link type="danger" :disabled="!acctPreseFlag" v-else class="vg_cursor" @click="delType(scope)">移除</el-link>
              </template>
            </el-table-column>
          </el-table>
        </el-card>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import {get,post} from "@api/request";
import {podrAPI} from "@api/modules/podr";
import {acctAPI} from "@api/modules/acct";
import {cpttAPI} from "@api/modules/comptitle";
import editHeader from "@/views/component/editHeader";
import inputUser from "@/views/component/inputUser";
import PodrAddEditIn from "@/views/PurchaseManagement/PodrManage/TabChild/Componet/PodrAddEditIn"
import PodrAddProd from "@/views/PurchaseManagement/PodrManage/TabChild/Componet/TabPanel/PodrAddProd"

export default {
  name: "smplAddMain",
  components: {
    editHeader,
    inputUser,
    PodrAddEditIn,
    PodrAddProd,
  },
  data() {
    return {
       rules:{
        podr_no:[
          { required: true, trigger: 'blur' , message: ' '},
        ],
        podr_cptt_id:[
          { required: true, trigger: 'blur' , message: ' '},
        ],
        cptt_id:[
          { required: true, trigger: 'blur' , message: ' '},
        ],
        podr_total:[
          { required: true, trigger: 'blur' , message: ' '},
        ],
        extra_price:[ {
            pattern: /^\d{0,4}(\.\d{1,4})?$/,
            message: "最大只能输入9999.9999并且只能输入数字且最多4位小数!",   
          },]
      },
      isShow: true,
      podrForm:{},
      cpttFctrGroupOpt:[],
      smplEditIn:{},
      notice:false,
      podrProdLi:[],
      removePodrProdLi:[],
      cpptForm:[],
      podrFormLi:[],
      btn:{},
      title:'产品信息表',
      stffForm:{
      },
      loadingFlag:true,
      drawer: false,
      direction: 'rtl',
      acctCopy:[],
      specialDataFlag:false,
      podrAcctCope:[],
      acctPreseFlag:false
    }
  },
  watch: {
  },
  created() {
    this.initData()
  },
  methods:{
     initData(){
      this.loadingFlag = true
      this.getList()
      this.getCppt()
      this.getAccts()
      this.getCpttPurchaser()
      if(this.$cookies.get('userInfo').acct_id === 1){
        this.specialDataFlag = true
      }
     },
    //提交表单
    submit(formName){
      if(this.podrForm.podr_prod_list.length<1){
        return this.$message.warning('至少添加一条产品')
      }
      this.$confirm('确认提交?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.saveInfo()
          } else {
            console.log('error submit!');
            return false;
          }
        });
      }).catch(()=>{})
    },
    // 保存
    saveInfo() {
      let podrForm = {};
      podrForm = Object.assign(podrForm,this.podrForm);
      let str1 = null;
      let str2 = null;
      this.cpptForm.forEach(item=>{
        if(item.cptt_id === this.podrForm.podr_cptt_id){
          str2 = item.cptt_name
        }
      })
      this.cpttFctrGroupOpt.forEach(item=>{
        if(item.cptt_id === this.podrForm.cptt_id){
          str1 = item.cptt_name
        }
      })
      podrForm.cptt_name = str1;
      podrForm.podr_cptt_name = str2;
      podrForm.podr_date = (new Date(this.podrForm.podr_date)).getTime() / 1000;
      this.podrFormLi = this.podrFormLi.filter(item => this.podrForm.podr_prod_list.every(item1 => item.podr_prod_id != item1.podr_prod_id));
      for(let i=0;i<this.podrFormLi.length;i++){
        this.podrFormLi[i].destroy_flag = 1
      }
      podrForm.podr_prod_list = podrForm.podr_prod_list.concat(this.podrFormLi);
      this.podrAcctCope = this.podrAcctCope.filter(item=>podrForm.podr_acct_list.every(item1=>item.podr_acct_id !== item1.podr_acct_id))
      for(let i=0;i<this.podrAcctCope.length;i++){
        this.podrAcctCope[i].destroy_flag = 1
      }
      podrForm.podr_acct_list = podrForm.podr_acct_list.concat(this.podrAcctCope);
      post(podrAPI.editPodr,podrForm)
      .then(res => {                                                    
        if(res.data.code === 0){
          this.$message({
            type:'success',
            message:'保存成功'
          })
          this.isShow = true;
          this.$emit('isShow',this.isShow)
          this.initData()
        } else if(res.data.code === 7){
          this.$message({
            type:'error',
            message:'单据编号重复'
          })
          this.podrForm.podr_date = (new Date(this.podrForm.podr_date)).getTime() * 1000;
        } else {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({message: mg, type: tp});
          this.podrForm.podr_date = (new Date(this.podrForm.podr_date)).getTime() * 1000;
          this.isShow = true;
          this.$emit('isShow',this.isShow)
          this.initData()
        }
      })
      .catch((res)=>{
        let mg = res.data.msg;
        let tp = 'error';
        this.$message({message: mg, type: tp});
      })
    },
    // 刷新按钮
    buttonRefresh(){
      this.initData()
    },
    //撤销编辑
    closeEdit(){
      this.$confirm('是否撤销编辑?', '提示', {
        confirmButtonText: '是',
        cancelButtonText: '否',
        type: 'warning'
      }).then(() => {
        this.initData()
        this.isShow = true
        this.$emit('isShow',this.isShow)
        this.$message({
          type: 'info',
          message: '已撤销编辑!'
        });
      }).catch(()=>{})
    },
    //启用编辑
    openEdit(){
      this.isShow = false;
      this.$emit('isShow',this.isShow)
    },
    // 获取账套accts
    getAccts(){
      get(acctAPI.getAllAcctsV1)
      .then(res =>{
        if(res.data.code === 0){
          this.acctCopy = res.data.data;
          for(let i=0;i<this.acctCopy.length;i++){
            if(this.$cookies.get('userInfo').acct_id === this.acctCopy[i].acct_id){
              this.acctCopy[i].disabled  = true
            }
          }
        }else if(res.data.code === 5){
          console.log('  ');
        }else{
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({message:mg,type:tp});
        }
      })
      .catch((res)=>{
        let mg = res.data.msg;
        let tp = 'error';
        this.$message({message:mg,type:tp});
      })
    },
    // 关闭特殊数据范围
    handleCloseDrawer(){
      let flag = false;
      for(let i=0;i<this.podrForm.podr_acct_list.length;i++){
        if(this.podrForm.podr_acct_list[i].acct_id === null){
          flag = true;
        }
      }
      if(flag){
        this.$message.warning('请把分享的账套补充完整')
      }else{
        if(this.acctPreseFlag){
          this.$message.warning('请先保存或取消分享账套的编辑')
        }else{
          this.drawer = false
        }
      }
    },
    // 保存分享账套
    preseType(){
      let temp = false
      for(let i=0;i<this.podrForm.podr_acct_list.length;i++){
        if(this.podrForm.podr_acct_list[i].acct_id === null){
          temp = true
        }
      }
      if(temp){
        this.$message.warning("请把账套名称补充完整")
      }else{
        this.$confirm("确认保存?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
        .then((res)=>{
          let acctList = JSON.parse(JSON.stringify(this.podrForm.podr_acct_list))
          this.podrAcctCope = this.podrAcctCope.filter(item=>acctList.every(item1=>item.podr_acct_id !== item1.podr_acct_id))
          for(let i=0;i<this.podrAcctCope.length;i++){
            this.podrAcctCope[i].destroy_flag = 1
          }
          acctList = acctList.concat(this.podrAcctCope)
          if(acctList.length>0){
            post(podrAPI.podrShareAccts,{podr_id:this.podrForm.podr_id,podr_acct_list:acctList})
            .then(res=>{
              if(res.data.code === 0){
                this.$message.success("保存成功")
                // this.drawer = false
                this.acctPreseFlag = false
                this.initData()
              }else{
                let mg = res.data.msg;
                let tp = 'error';
                this.$message({message:mg,type:tp});
              }
            })
            .catch(res=>{
              let mg = res.data.msg;
              let tp = 'error';
              this.$message({message:mg,type:tp});
            })
          }else{
            this.$message.success("保存成功")
            this.acctPreseFlag = false
            this.initData()
          }
        })
        .catch(()=>{})  
      }
    },
    // 取消分享账套
    cancelType(){
      this.$confirm("确认取消?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
      .then((res)=>{
        this.acctPreseFlag = false
        this.initData()
      })
      .catch(()=>{})
    },
    // 特殊数据范围添加
    addType() {
      let item = {
        acct_id:null
      };
      this.podrForm.podr_acct_list.push(item)
    },
    // 特殊数据范围添加删除
    delType(scope){
      if(scope.row.disabled){
        this.$message.error("该账套不能删除")
      }else{
        this.$confirm("确认删除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.podrForm.podr_acct_list.splice(scope.$index,1)
        })
        .catch(() => {});
      }
    },
    // 选择账套重复判断
    acctSelect(val){
      if(val.row.acct_id !== null){
        for(let i=0;i<this.podrForm.podr_acct_list.length;i++){
          if(i !== val.$index){
            if(this.podrForm.podr_acct_list[i].acct_id === val.row.acct_id){
              val.row.acct_id = null;
              this.$message({
                type:'warning',
                message:'账套选择重复'
              })
              return ;
            }
          }
        }
      }
    },
    //删除选择的内容
    handleSelect(val){
      this.removePodrProdLi = val;
    },
    // 修改其他金额改变总金额
    extraPriceChange(){
      this.podrForm.extra_price = this.helper.calcPrice(this.podrForm.extra_price,4,1000000)
      let temp = 0;
      if(this.podrForm.podr_prod_list.length>0){
        for(let i=0;i<this.podrForm.podr_prod_list.length;i++){
          if(this.podrForm.podr_prod_list[i].podr_prod_num!==undefined && this.podrForm.podr_prod_list[i].podr_prod_price!==undefined){
            let num1 = Number(this.podrForm.podr_prod_list[i].podr_prod_num)*100000
            let num2 = Number(this.podrForm.podr_prod_list[i].podr_prod_price)*100000
            temp = (num1*num2)/10000000000 + temp
          }
        }
      }
      if(this.podrForm.extra_price){
        temp = temp + Number(this.podrForm.extra_price)
      }
      this.podrForm.podr_total = this.helper.haveFour(Number(temp));
    },
    // 删除产品信息按钮
    delBank(){
      if(this.podrForm.podr_prod_list.length <= 0){
        return this.$message.warning('至少选择一条数据')
      }
      this.podrForm.podr_prod_list = this.podrForm.podr_prod_list.filter(item => this.removePodrProdLi.every(item1 => item.prod_id != item1.prod_id));
      let temp = 0;
      if(this.podrForm.podr_prod_list.length>0){
        for(let i=0;i<this.podrForm.podr_prod_list.length;i++){
          if(this.podrForm.podr_prod_list[i].podr_prod_num!==undefined && this.podrForm.podr_prod_list[i].podr_prod_price!==undefined){
            let num1 = Number(this.podrForm.podr_prod_list[i].podr_prod_num)*100000
            let num2 = Number(this.podrForm.podr_prod_list[i].podr_prod_price)*100000
            temp = (num1*num2)/10000000000 + temp
          }
        }
      }
      if(this.podrForm.extra_price){
        temp = temp + Number(this.podrForm.extra_price)
      }
      this.podrForm.podr_total = this.helper.haveFour(Number(temp));
    },
    // 打开导入产品信息小窗口
    addRow(){
      this.notice = true;
    },
    // 导入产品信息小窗口确定按钮
    confirmIn(val){
      this.podrProdLi = val;
      this.notice = false;
      this.podrProdLi = this.podrProdLi.filter(item => this.podrForm.podr_prod_list.every(item1 => item.prod_id != item1.prod_id));
      this.podrForm.podr_prod_list = this.podrForm.podr_prod_list.concat(this.podrProdLi);
      this.$refs.PodrAddEditIn.clear()
    },
    // 取消按钮
    cancelPodrIn(){
      this.notice = false
    },
    // 获取采购方
    getCpttPurchaser(){
      get(cpttAPI.get_my_cptts_v1)
      .then(res=>{
        if(res.data.code === 0){
          this.cpttFctrGroupOpt = res.data.data
        }else{
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({message: mg, type: tp});
        }
      })
      .catch((res)=>{
        let mg = res.data.msg;
        let tp = 'error';
        this.$message({message: mg, type: tp});
      })
    },
    // 获取合作单位信息
    getCppt(){
      get(cpttAPI.getAllCpttsV1)
      .then(res=>{
        if (res.data.code === 0) {
          this.cpptForm = res.data.data;
        }
      })
      .catch(res => {
        let mg = res.data.msg;
        let tp = 'error';
        this.$message({message: mg, type: tp});
      })
    },
    // 获取信息步骤
    getList(){
      let podr_id = this.$route.query.form_id
      get(podrAPI.getPodrById,{podr_id:podr_id})
      .then(res=>{
        if (res.data.code === 0) {
           // 权限按钮
          this.btn = res.data.data.btn;
          this.podrForm = res.data.data.form;
          this.getInputUser()
          if(this.podrForm.podr_date){
            this.podrForm.podr_date = this.podrForm.podr_date*1000;
          }else{
            this.podrForm.podr_date = null
          }
          this.podrAcctCope = JSON.parse(JSON.stringify(this.podrForm.podr_acct_list))
          this.podrForm.podr_total = this.helper.haveFour(this.podrForm.podr_total)
          this.podrForm.extra_price = this.helper.haveFour(this.podrForm.extra_price)
          for(let i=0;i<this.podrForm.podr_prod_list.length;i++){
            if(this.podrForm.podr_prod_list[i].podr_prod_price){
              this.podrForm.podr_prod_list[i].podr_prod_price = this.helper.haveFour(this.podrForm.podr_prod_list[i].podr_prod_price)
            }
          }
          this.podrFormLi = JSON.parse(JSON.stringify(this.podrForm.podr_prod_list))
          setTimeout(() => {
            this.loadingFlag = false
          }, 500);
        }else{
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({message: mg, type: tp});
        }
      })
      .catch(res => {
        let mg = res.data.msg;
        let tp = 'error';
        this.$message({message: mg, type: tp});
      })
    },
    //获取录入人
    getInputUser(){
      this.stffForm.cptt_id = this.podrForm.cptt_id;
      this.stffForm.user_id = this.podrForm.user_id;
      this.stffForm.dept_id = this.podrForm.dept_id;
      this.stffForm.stff_id = this.podrForm.stff_id;
      this.stffForm.cptt_abbr = this.podrForm.cptt_abbr;
      this.stffForm.dept_name = this.podrForm.dept_name;
      this.stffForm.dept_team_id = this.podrForm.dept_team_id;
      this.stffForm.dept_team_name = this.podrForm.dept_team_name;
      this.stffForm.stff_name = this.podrForm.stff_name;
    },
    //表单重置
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
  }
}
</script>

<style scoped lang="scss">
.vd_bortop {
  border-top: #DCDFE6 solid 1px;
  padding-top: 20px;
}
.vd_mrt{
  margin-top: 20px;
}
.vd_eltab {
  margin-top: 10px;
}
.vd_edhea{
  position: relative;
}
.vd_export{
  position: absolute;
  top: 0;
  left: 65px;
}
.vd_exports{
  position: absolute;
  top: 0;
  left: 120px;
}
.vd_draw{
  padding: 20% 10%;
}
.vd_export1{
  position: absolute;
  top: 0;
  left: 120px;
}
</style>