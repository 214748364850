<template>
  <div>
    <el-table highlight-current-row ref="multiTable" :data="prodForm.podr_prod_list" border @selection-change="handleSelectionChange">
      <el-table-column type="selection" width="48" align="center"></el-table-column>
      <el-table-column label="序号" width="48" align="center">
        <template slot-scope="scope">
          {{ scope.$index+1 }}
        </template>
      </el-table-column>
      <el-table-column label="产品编号">
        <template slot-scope="scope">
          <el-form-item label-width="0" :prop="'podr_prod_list.'+scope.$index+'.prod_no'" :rules="[{required:true}]">
            <el-input :disabled="true" v-model="prodForm.podr_prod_list[scope.$index].prod_no" maxlength="10" show-word-limit placeholder="暂无物料编号"></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="产品名称">
        <template slot-scope="scope">
          <el-form-item label-width="0" :prop="'podr_prod_list.'+scope.$index+'.prod_name'" :rules="[{required:true}]">
            <el-input :disabled="true" v-model="prodForm.podr_prod_list[scope.$index].prod_name" maxlength="30" show-word-limit placeholder="暂无物料名称"></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <!-- <el-table-column label="产品规格">
        <template slot-scope="scope">
          <el-form-item label-width="0" :prop="'podr_prod_list.'+scope.$index+'.prod_spec'" :rules="[{required:true}]">
            <el-input disabled v-model="prodForm.podr_prod_list[scope.$index].prod_spec" maxlength="30" show-word-limit placeholder="暂无产品规格"></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="产品类型">
        <template slot-scope="scope">
          <el-form-item label-width="0" :prop="'podr_prod_list.'+scope.$index+'.prod_type'" :rules="[{required:true}]">
            <el-select disabled v-model="prodForm.podr_prod_list[scope.$index].prod_type" placeholder="请选择产品类型" clearable>
              <el-option
                  v-for="item in prodTypeGroupOpt"
                  :key="item.id"
                  :label="item.param1"
                  :value="item.param1">
              </el-option>
            </el-select>
          </el-form-item>
        </template>
      </el-table-column> -->
      <el-table-column>
        <template slot="header">
          <span class="vg_deep_red">数量</span>
        </template>
        <template slot-scope="scope">
          <el-form-item label-width="0" :prop="'podr_prod_list.'+scope.$index+'.podr_prod_num'" :rules="[{required:true}]">
            <el-input :disabled='prodForm.status===2'  @change="totalAmount(scope,1)" @input ="prodForm.podr_prod_list[scope.$index].podr_prod_num = helper.keepTNum(prodForm.podr_prod_list[scope.$index].podr_prod_num)" show-word-limit maxlength="13" v-model="prodForm.podr_prod_list[scope.$index].podr_prod_num" placeholder="暂无数量"></el-input> 
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column>
        <template slot="header">
          <span class="vg_deep_red">价格(元)</span>
        </template>
        <template slot-scope="scope">
          <el-form-item label-width="0" :prop="'podr_prod_list.'+scope.$index+'.podr_prod_price'" :rules="[{required:true}]">
            <el-input @change="totalAmount(scope,2)" @input ="prodForm.podr_prod_list[scope.$index].podr_prod_price = helper.keepTNum1(prodForm.podr_prod_list[scope.$index].podr_prod_price)" v-model="prodForm.podr_prod_list[scope.$index].podr_prod_price" maxlength="11" show-word-limit placeholder="暂无价格"></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column v-if="isShow" width="120" align="center">
        <template slot-scope="scope">
          <el-link type="primary" class="vg_cursor" @click="jumpPodrProd(scope.row)">查看</el-link>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import {get,post} from "@api/request";
import {optnAPI} from "@api/modules/optn";

export default {
  name:"ProdAddProd",
  props:{
    prodForm:{
      type: Object,
      required:true,
    },
    isShow:{
      type:Boolean,
      default:false,
    }
  },
  data(){
    return {
      selectionsList:[],
      tableHeight:269,
      prodTypeGroupOpt:[],
    }
  },
  created(){
    // this.initData()
  },
  methods:{
    initData(){
      this.getSmplType()
    },
    // 获取玩具类型
    getSmplType(flag){
      get(optnAPI.getAllContent,{perm_id : 10003})
      .then(res=>{
        if(res.data.code === 0) {
          this.prodTypeGroupOpt = res.data.data;
          console.log()
        }
      })
    },
    // 清空
    clear(){
      this.$refs.multiTable.clearSelection();
      this.multiSelection = null
    },
    //产品信息选择框
    handleSelectionChange(selection) {
      this.selectionsList = [];
      this.selectionsList = selection
      this.$emit("handleSelectionChange",this.selectionsList)
    },
    // 跳转产品工单
    jumpPodrProd(row){
      const permId = this.$route.query.perm_id
      this.jump('/podt_edit',{perm_id:133,form_id:row.podr_prod_id})
    },
    // 计算总金额
    totalAmount(scope,type){ 
      if(type === 2){  scope.row.podr_prod_price = this.helper.haveFour(scope.row.podr_prod_price)
      }
      let temp = 0;
      if(this.prodForm.podr_prod_list.length>0){
        for(let i=0;i<this.prodForm.podr_prod_list.length;i++){
          if(this.prodForm.podr_prod_list[i].podr_prod_num!==undefined && this.prodForm.podr_prod_list[i].podr_prod_price!==undefined){
            let num1 = Number(this.prodForm.podr_prod_list[i].podr_prod_num)*100000
            let num2 = Number(this.prodForm.podr_prod_list[i].podr_prod_price)*100000
            temp = (num1*num2)/10000000000 + temp
          }
        }
      }
      if(this.prodForm.extra_price){
        temp = temp + Number(this.prodForm.extra_price)
      }
      this.prodForm.podr_total = this.helper.haveFour(Number(temp));
    }
  },
}
</script>

<style scoped>

</style>>
