<template>
 <div>
   <child :isShow="isShow"></child>
 </div>
</template>

<script>
import docuAbout from "@/views/component/docuCommon/docuAbout"
export default {
name: "SmplEditAbout",
props:{
  isShow:{
    type:Boolean,
    required:true,
  }
},
components:{
  child:docuAbout,
}
}
</script>

<style scoped>

</style>